import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Design Architecture" link="https://design-architecture.vercel.app/" bg="linear-gradient(to left, #D063A6 0%, #98B5DF 100%)" mdxType="ProjectCard">
This is simple web page build with HTML/CSS, JQuery, Grid, Flex.
    </ProjectCard>
    <ProjectCard title="Minimalist Blog" link="https://minimalistblog.vercel.app/" bg="linear-gradient(to left, #043978 0%, #92E1C0 100%)" mdxType="ProjectCard">
Adaptive web page build with React and React Router.
    </ProjectCard>
    <ProjectCard title="The HAM" link="https://theham.vercel.app/" bg="linear-gradient(to right, #5F3EA7 0%, #6DA8CF 100%)" mdxType="ProjectCard">
Single page application build with Flex, Grid and JS. 
    </ProjectCard>
    <ProjectCard title="Forkio" link="https://forkio.vercel.app/" bg="linear-gradient(to right, #45658D 0%, #EFEFEF 100%)" mdxType="ProjectCard">
Responsive page build with HTML, SaSS, JS, Browser-sync,  Gulp.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      